.donate {
  font-family: "Rubik" !important;
  .deck {
    gap: 20px;
  }
  .op-0 {
    opacity: 0;
  }
  .intro {
    text-align: center;
    .title {
      @include font-size(35px);
    }
    .separator {
      background: $color-primary;
      border: none;
      border-radius: 40px;
      width: 10%;
      height: 6px;
      margin: 15px 0px;
      display: block;
      margin-left: auto;
      margin-right: auto;
    }
    .text {
      @include font-size(18px);
      color: $color-darker;
    }
  }
  @media (max-width: 991px) {
    .card {
      width: 100% !important;
      margin: 0 !important;
    }
    .col-6 {
      margin: 20px 0;
      flex: 0 0 45% !important;
    }
  }
  .card {
    background: none;
    width: 24.9%;
    border: none;
    border-radius: 20px;
    text-align: center;
    font-family: "Rubik";
    color: $color-dark;
    min-height: 166px;
    cursor: pointer;
    position: relative;
    &.time {
      &::after {
        width: 100px;
        height: 100px;
        position: absolute;
        top: -45px;
        z-index: 2;
        right: 10px;
        content: "";
        background: url("../img/timer.png") no-repeat;
        background-size: contain;
      }
    }
    h3,
    .v {
      @include font-size(40px);
      margin: auto;
      position: relative;
    }
    @media (min-width: 992px) {
      &:hover h3::before,
      &:hover .v::before,
      &.active h3::before,
      &.active .v::before {
        background-image: url("../img/v.svg");
        background-repeat: no-repeat;
        background-size: contain;
        position: absolute;
        width: 26.68px;
        height: 20.08px;
        content: "";
        top: -30px;
        left: 50%;
        transform: translateX(-50%);
      }
    }

    p {
      @include font-size(18px);
      b {
        color: #fd3433;
      }
    }
    img {
      display: block;
      margin-left: auto;
      margin-right: auto;
      padding: 10px 0px;
      opacity: 0;
    }
    transition: 0.2s ease-in-out 0s;
    &:hover {
      color: $color-white;
      background-color: #176370;
      box-shadow: 0px 0px 26px 6px rgba(0, 0, 0, 0.38);
      b {
        color: $color-white;
      }
      img {
        opacity: 1;
      }
    }
    &.active {
      color: $color-white;
      background-color: #176370;
      box-shadow: 0px 0px 26px 6px rgba(0, 0, 0, 0.38);
      b {
        color: $color-white;
      }
      img {
        opacity: 1;
      }
    }
    &.custom {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: row;
      flex-wrap: nowrap;
      label {
        @include font-size(30px);
        width: 15%;
        line-height: 25px;
        text-align: center;
      }
      input {
        border-radius: 4px;
        font-family: "Rubik";
        text-align: center;
        font-size: 0.75rem;
        border: none;
        padding: 10px;
        &::-webkit-input-placeholder {
          /* Chrome/Opera/Safari */
          color: #707070;
          text-align: center;
          @include font-size(16px);
          font-family: "Rubik";
          text-transform: uppercase;
          opacity: 1;
        }
        &::-moz-placeholder {
          /* Firefox 19+ */
          color: #707070;
          text-align: center;
          @include font-size(16px);
          font-family: "Rubik";
          text-transform: uppercase;
          opacity: 1;
        }
        &:-ms-input-placeholder {
          /* IE 10+ */
          color: #707070;
          text-align: center;
          @include font-size(16px);
          font-family: "Rubik";
          text-transform: uppercase;
          opacity: 1;
        }
        &:-moz-placeholder {
          /* Firefox 18- */
          color: #707070;
          text-align: center;
          @include font-size(16px);
          font-family: "Rubik";
          text-transform: uppercase;
          opacity: 1;
        }
      }
    }
  }
  hr {
    height: 100px;
    width: 3px;
    border-radius: 20px;
    margin: auto;
    background-color: white;
  }
  .card-mb {
    width: 80%;
    margin: auto;
    padding: 2rem;
    border-radius: 20px;
    font-weight: 500;
    display: flex;
    flex-direction: row;
    background-color: $color-secondary-fade;
    color: #707070;
    label {
      font-size: 1.5rem;
    }
    h3 {
      font-size: 2.063rem;
    }
    p {
      opacity: 0.5;
      font-size: 1rem;
    }
    img {
      opacity: 0;
    }
    &:hover,
    &.active {
      h3,
      p,
      label {
        color: white;
      }
      img {
        opacity: 1;
      }
      background-color: #176370;
    }
    input {
      border-radius: 4px;
      font-family: "Rubik";
      text-align: center;
      font-size: 0.75rem;
      border: none;
      padding: 10px;
      &::-webkit-input-placeholder {
        /* Chrome/Opera/Safari */
        color: #707070;
        text-align: center;
        @include font-size(16px);
        font-family: "Rubik";
        text-transform: uppercase;
        opacity: 1;
      }
      &::-moz-placeholder {
        /* Firefox 19+ */
        color: #707070;
        text-align: center;
        @include font-size(16px);
        font-family: "Rubik";
        text-transform: uppercase;
        opacity: 1;
      }
      &:-ms-input-placeholder {
        /* IE 10+ */
        color: #707070;
        text-align: center;
        @include font-size(16px);
        font-family: "Rubik";
        text-transform: uppercase;
        opacity: 1;
      }
      &:-moz-placeholder {
        /* Firefox 18- */
        color: #707070;
        text-align: center;
        @include font-size(16px);
        font-family: "Rubik";
        text-transform: uppercase;
        opacity: 1;
      }
    }
  }
  .deduc {
    color: #686869;
    font-size: 1rem;
  }
  .asterisque {
    color: #707070;
    font-size: 12px;
  }
}

a {
  color: inherit;
  text-decoration: none;
  &:hover {
    color: inherit;
    text-decoration: none;
  }
}
