.white {
  color: $color-white;
}
.dark-white {
  color: $color-dark;
}
.primary {
  color: $color-primary;
}
.secondary {
  color: $color-secondary;
}
.dark {
  color: $color-dark;
}
.title {
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  text-transform: uppercase;
}
.col-title {
  padding: 0 30px;
}
html,body {
  height: 100%;
}
body {
  min-height: 100vh;
  /* mobile viewport bug fix */
  min-height: -webkit-fill-available;
}

body::after{
  position:absolute; width:0; height:0; overflow:hidden; z-index:-1; // hide images
  content:url("https://adfinitas-statics-cdn.s3.eu-west-3.amazonaws.com/JDS/Hiver24/LP/date1.jpg") url("https://adfinitas-statics-cdn.s3.eu-west-3.amazonaws.com/JDS/Hiver24/LP/date2.jpg") url("https://adfinitas-statics-cdn.s3.eu-west-3.amazonaws.com/JDS/Hiver24/LP/date3.jpg") url("https://adfinitas-statics-cdn.s3.eu-west-3.amazonaws.com/JDS/Hiver24/LP/date4.jpg") url("https://adfinitas-statics-cdn.s3.eu-west-3.amazonaws.com/JDS/Hiver24/LP/date5.jpg") url("https://adfinitas-statics-cdn.s3.eu-west-3.amazonaws.com/JDS/Hiver24/LP/date6.jpg") url("https://adfinitas-statics-cdn.s3.eu-west-3.amazonaws.com/JDS/Hiver24/LP/date7.jpg")url("https://adfinitas-statics-cdn.s3.eu-west-3.amazonaws.com/JDS/Hiver24/LP/date1-mb.jpg") url("https://adfinitas-statics-cdn.s3.eu-west-3.amazonaws.com/JDS/Hiver24/LP/date2-mb.jpg") url("https://adfinitas-statics-cdn.s3.eu-west-3.amazonaws.com/JDS/Hiver24/LP/date3-mb.jpg") url("https://adfinitas-statics-cdn.s3.eu-west-3.amazonaws.com/JDS/Hiver24/LP/date4-mb.jpg") url("https://adfinitas-statics-cdn.s3.eu-west-3.amazonaws.com/JDS/Hiver24/LP/date5-mb.jpg") url("https://adfinitas-statics-cdn.s3.eu-west-3.amazonaws.com/JDS/Hiver24/LP/date6-mb.jpg") url("https://adfinitas-statics-cdn.s3.eu-west-3.amazonaws.com/JDS/Hiver24/LP/date7-mb.jpg") ;   // load images
}

html {
  height: -webkit-fill-available;
}