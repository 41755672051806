.event {
  font-family: "Rubik";
  .text  {
    @include font-size(20px);
    line-height: 125%;
    color: $color-darker;
    position: relative;
  }
  .alinea {
    font-size: 1.25rem;
    color: #AFAFAF;
  }
  .quote {
    width: 100%;
    @include font-size(20px);
    line-height: 24px;
    color: #686869;
    text-align: center;
    border-left: solid 2px rgba(112, 112, 112, 0.3);
  }
  .f-letter {
    @include font-size(38px);
  }
  &-container {
    background-color: rgba(112, 112, 112, 0.05);
    border-radius: 30px;
    color: $color-secondary;
    text-align: center;
    height: 100%;
    padding: 5%;
    @media (max-width: 768px) {
      padding: 15%;
    }
  }
  &-item {
    color: $color-primary;
    h1 {
      @include font-size(100px);
      line-height: 70px;
      margin: auto 0px;
      max-height: 70px;
    }
    .desc-wrapper {
      height: 20%;
    }
    .num-wrapper {
      height: 80%;
    }
    .desc {
      @include font-size(25px);
    }
    img {
      height: 70px;
    }
  }
  &-separator {
    background-color: rgba(131, 216, 199, 0.5);
    min-width: 1px;
    min-height: 100%;
    @media (max-width: 768px) {
      margin: 18% 0px;
      min-height: 1px;
      min-width: 100%;
    }
  }
  .side {
    object-fit: fill;
    width: 100%;
    margin-bottom: 1rem;
  }
}
