header {
  position: relative;
  height: 87vh;
  background-image: url("../img/header-background.jpg");
  background-repeat: no-repeat;
  background-position: center top;
  background-size: cover;
  color: $color-white;
  margin-top: 13vh;
  @media (max-width: 768px) {
    background-image: url("../img/header-background-mb.jpg");
    background-position: 23% 0;
  }
  .text {
  }
  h2 {
    @media (max-width: 768px) {
      font-size: 1.688rem;
    }
    font-size: 2rem;
    align-self: center;
    width: 100%;
    text-align: center;
    font-family: "Rubik";
    color: $color-white;
    text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.5);
    // margin-bottom: 5px;
    line-height: 30px;
    font-weight: bold;
    font-style: italic;
  }
  .verser {
    @media (max-width: 768px) {
      margin: 20px auto;
      width: 65%;
    }
    width: 100%;
    margin: 0px auto 20px auto;
    text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.5);
    text-align: center;
    font-family: "Rubik";
    color: $color-white;
    font-size: 0.875rem;
    line-height: 1.0625rem;
    font-weight: 500;
  }
  .subtitle {
    @media (max-width: 768px) {
      margin: 20px auto;
      width: 65%;
    }
    width: 100%;
    margin: 0px auto 20px auto;
    text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.5);
    text-align: center;
    font-family: "Rubik";
    color: $color-white;
    font-size: 1.625rem;
    line-height: 31px;
    font-weight: 500;
  }
  h1 {
    @media (max-width: 768px) {
      font-size: 2.688rem;
    }
    font-size: 4.875rem;
    width: 100%;
    position: relative;
    text-align: center;
    font-family: "Rubik";
    color: #63dbc7;
    text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.5);
    margin-bottom: 0px;
    font-weight: bold;
    font-style: italic;
    z-index: 2;
  }
  .underline {
    width: 10%;
    height: 8px;
    display: block;
    background-color: $color-primary;
    border-radius: 28px;
    margin-left: auto;
    margin-right: auto;
    border: none;
  }
  #header-bottom-arrow {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 20px;
    width: 100px;
    margin-left: auto;
    margin-right: auto;
    @media (max-width: 768px) {
      bottom: 20px;
    }
  }
}

.shadow {
  position: absolute;
  width: 100%;
  height: 80%;
  background: rgb(0, 0, 0);
  background: linear-gradient(
    0deg,
    rgba(0, 0, 0, 0.96) 0%,
    rgba(0, 0, 0, 0) 100%
  );
  bottom: 0;
  left: 0;
  z-index: 1;
}

.content {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 2;
}
header button.play {
  background: 0 0;
  border: none;
  margin-bottom: 1.2rem;
  margin-left: auto;
  margin-right: auto;
}
header button.play img {
  cursor: pointer;
}
header button.play:hover {
  transform: scale(1.1);
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 0.3s;
}
header .player {
  display: none;
  position: absolute;
  top: 5%;
  left: 10%;
  width: 80%;
  height: 80%;
  z-index: 9999;
}
header .close {
  margin-bottom: 1rem;
  color: #fff;
  box-shadow: none;
  opacity: 1;
  text-shadow: none;
  font-size: 1.1rem;
}

.btn-container:hover .video-text {
  opacity: 1;
}

.video-text {
  opacity: 0;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 0.3s;
  text-align: center;
  font-size: 1.625rem;
  text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.5);
  text-align: center;
  font-family: "Rubik";
  color: $color-white;
  font-size: 1.4rem;
  line-height: 31px;
  font-weight: 500;
}
