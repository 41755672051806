.countdown {
  position: relative;
  height: 120px;
  width: 120px;
  text-align: center;
}

.countdown-label {
  font-size: 0.8rem;
}

.countdown-text {
  position: absolute;
  font-weight: 600;
  font-family: "Rubik";
  color: rgba(255, 255, 255, 0.7);
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.countdown-value {
  font-size: 2.5rem;
  line-height: 2rem;
}

.circle-wrapper {
  position: absolute;
  top: 0;
  right: 0;
  width: 120px;
  height: 120px;
  transform: rotateY(-180deg) rotateZ(-90deg);
}

.circle {
  stroke-dasharray: 314px;
  stroke-width: 8px;
  stroke: rgba(255, 255, 255, 0.2);
  fill: none;
}

.circle.rotate {
  stroke: rgba(255, 255, 255, 0.5);
  stroke-dashoffset: 157px;
}