.date {
  background: url("https://adfinitas-statics-cdn.s3.eu-west-3.amazonaws.com/JDS/Hiver24/LP/date1.jpg")
    no-repeat;
  @media (max-width: 768px) {
    background: url("https://adfinitas-statics-cdn.s3.eu-west-3.amazonaws.com/JDS/Hiver24/LP/date1-mb.jpg")
      no-repeat;
    background-position: center;
    background-size: cover;
  }
  background-size: cover;
  font-family: "Rubik";
  position: relative;
  margin-top: 200px !important;
  padding-bottom: 3rem !important;
  @media (max-width: 992px) {
    .date-bubble {
      h3 {
        @include font-size(52px);
        line-height: 90%;
      }
      b {
        @include font-size(17px);
      }
    }
    .active {
      display: block;
    }
    .plus-card {
      color: white;
      text-transform: none;
      color: white;
      font-style: normal;
      font-family: "Rubik";
      @include font-size(18px);
      line-height: 110%;
      text-align: left;
      z-index: 10;
    }
  }
  &::before {
    width: 382px;
    height: 232px;
    position: absolute;
    margin-bottom: 50px;
    z-index: 2;
    left: 50%;
    transform: translateX(-47%);
    top: -116px;
    content: "";
    background: url("../img/7date.png") no-repeat;
    background-size: contain;
  }
  &-container {
    margin-bottom: 75px;
    margin-top: 146px;
  }
  &-bubble {
    color: rgba(255, 255, 255, 0.5);
    background-color: rgba(23, 99, 112, 0.5);
    width: 120px;
    border-radius: 500px;
    height: 120px;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    h3 {
      @include font-size(52px);
      line-height: 90%;
    }
    b {
      @include font-size(17px);
    }
    &:hover {
      color: white;
      background-color: #63dbc7;
    }
  }
  .active {
    color: white;
    background-color: #63dbc7;
  }
  &-title {
    text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.5);
    font-size: 4.75rem;
    color: #63dbc7;
    text-align: center;
    position: relative;
    width: 90%;
    margin: 0 auto;
    @media (max-width: 992px) {
      font-size: 2.75rem;
    }
  }
  &-content-title1 {
    z-index: 2;
  }
  .bg-none {
    background: none;
  }
  .plus {
    &-card {
      padding: 40px;
      font-style: normal;
      color: white;
      border-radius: 20px;
      font-family: "Rubik";
      z-index: 10;
      @include font-size(18px);
      line-height: 110%;
      text-align: left;
    }

    &-mb {
      background: $color-primary;
      border: none;
      border-radius: 10px;
    }
  }
  .date-content-text {
    background-color: #176370;
    color: white;
    max-width: 55rem;
    width: 95%;
    padding: 20px;
    text-align: center;
    border-radius: 20px;
  }
  #card-mb {
    transform: none;
    max-width: auto !important;
    min-width: auto;
    padding: 20px;
    width: 100%;
  }
  .d-none-card {
    display: flex;
  }
}

#expand {
  display: none;
}

.text-container {
  gap: 1rem;
}

.btn-text {
  background-color: #63dbc7;
  aspect-ratio: 1;
  border: none;
  border-radius: 0.625rem;
  display: flex;
  img {
    margin: auto;
    width: 25px;
  }
}

.min-h-screen {
  min-height: 100vh;
}

// .quote::after {
//   background-image: url("../img/quote.svg");
//   background-repeat: no-repeat;
//   background-size: contain;
//   position: absolute;
//   width: 66.04px;
//   height: 57.79px;
//   content: "";
//   bottom: -15px;
//   margin-left: -28px;
//   transform: rotate(180deg);
//   z-index: 0;
//   @media (max-width: 992px) {
//     width: 36.72px;
//     height: 32.13px;
//     bottom: 0;
//     margin-left: -18.04px;
//   }
// }
// .quote::before {
//   background-image: url("../img/quote.svg");
//   background-repeat: no-repeat;
//   background-size: contain;
//   position: absolute;
//   width: 66.04px;
//   height: 57.79px;
//   content: "";
//   top: -25px;
//   margin-left: -30.04px;
//   z-index: 0;
//   @media (max-width: 992px) {
//     top: -13px;
//     margin-left: -17.04px;
//     width: 36.72px;
//     height: 32.13px;
//   }
// }

.citation {
  font-size: 1.375rem;
  color: white;
  z-index: 10;
}
