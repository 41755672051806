footer {
  @media (max-width: 992px) {
    margin: 25px 0px 0px 0px;
    padding: 25px;
  }
  border-top: 1px solid rgba(112, 112, 112, 0.2);
  margin: 100px 0px 0px 0px;
  padding: 50px;
  font-family: "Rubik";
  a {
    cursor: pointer;
  }
  .link {
    display: flex;
    justify-content: center;
    height: 50%;
    text-transform: uppercase;
    text-align: center;
    @media (max-width: 992px) {
      @include font-size(10px);
      a {
        padding: 0px !important;
      }
      display: flex;
      justify-content: space-around;
    }
    a {
      padding: 0px 25px;
      color: $color-darker;
    }
  }
  .SIREN {
    height: 50%;
    text-align: center;
    color: $color-darker;
    opacity: 0.5;
  }
  .top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .footer-link {
      list-style: none;
      display: flex;
      color: $color-white;
      margin: 0;
      padding: 0;
      li {
        margin: 0 15px;
        &:first-child {
          margin: 0 0 15px;
        }
        a {
          color: $color-white;
          &:hover {
            color: $color-white;
          }
        }
      }
    }
    .social-footer-link {
      opacity: 1;
      transition: 0.25s;
      &:hover {
        opacity: 0.8;
      }
    }
  }
  .footer-line {
    margin-top: 10px;
    height: 2px;
    width: 100%;
    background-color: $color-primary;
  }
  p {
    font-size: 12px;
    color: #ecf3fd;
    margin-top: 10px;
    margin-bottom: 50px;
  }
}
