#popexit {
  background: rgba(0, 0, 0, 0.5);
  position: fixed;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  z-index: 9999999999999999999999999999;
  .card {
    background-color: $color-primary;
    margin: auto;
    padding: 1.5rem 3rem;
    border-radius: 20px;
    display: flex;
    .close-btn {
        background: none;
        border: none;
    }
    .text-small {
      font-size: 1.313rem;
    }
    .text-large {
      font-size: 2.125rem;
      line-height: 2.563rem;
    }
  }
}
